'use client';

import { TechRadarInterface } from '@deardigital/shared/interfaces';
import { radar_visualization } from '@deardigital/shared/utilities';
import { useEffect, useRef } from 'react';
import * as d3 from 'd3';

/* eslint-disable-next-line */
export interface TechRadarProps extends TechRadarInterface {}

export function TechRadar({ title, version, items }: TechRadarProps) {
  const svgRef = useRef<SVGSVGElement | null>(null);

  useEffect(() => {
    if (items && svgRef.current) {
      // Clear the existing SVG before creating a new one
      d3.select(svgRef.current).selectAll('*').remove();

      radar_visualization({
        svg_id: svgRef.current,
        width: 1450,
        height: 1000,
        colors: {
          background: '#fff',
          grid: '#dddde0',
          inactive: '#ddd',
        },
        title: title,
        date: version,
        quadrants: [{ name: 'Languages' }, { name: 'Platforms' }, { name: 'Tools' }, { name: 'Frameworks' }],
        rings: [
          { name: 'ADOPT', color: '#34A853' },
          { name: 'TRIAL', color: '#4285F4' },
          { name: 'ASSESS', color: '#fbbc05' },
          { name: 'HOLD', color: '#EA4335' },
        ],
        print_layout: true,
        links_in_new_tabs: true,
        // zoomed_quadrant: 0,
        entries: items,
      });
    }
  }, [items, title, version]);


  if (!items) {
    return null;
  }

  return (
    <svg ref={svgRef}></svg>
  );
}

export default TechRadar;