'use client';

import { ContentInfoCardCollapseInterface } from '@deardigital/shared/interfaces';
import { storyblokEditable } from '@storyblok/react';
import Section from '../../section/section';
import { Collapse } from './collapse';
import LinkRenderer from '../../link/link-renderer.component';
import SectionBadge from '../../badge/section-badge/section-badge';

export interface ContentInfoCardCollapseProps extends ContentInfoCardCollapseInterface {}

export function ContentInfoCardCollapse(props: ContentInfoCardCollapseProps) {
  if (!props.collapseItems) {
    return null;
  }

  return (
    <Section {...props.section}>
      <div {...(props._editable ? { ...storyblokEditable(props as any) } : {})}>
        <div className="container mx-auto px-5">
          {props.section.badge && <SectionBadge {...props.section.badge} spacing={props.section.spacing} />}
          <div className="lg:flex lg:gap-16">
            <div className="mb-14 lg:w-1/2 lg:mb-20">
              {/* @TODO: Change to rich text = more flexible */}
              <h2 className={`${props.section.theme.color.heading} font-medium font-serif text-5xl mb-6`}>
                {props.title}
              </h2>
              {/* @TODO: add multiple links */}
              {props.links && <LinkRenderer {...props.links[0]} />}
            </div>
            <Collapse items={props.collapseItems} section={props.section} />
          </div>
        </div>
      </div>
    </Section>
  );
}
