import { ICONS, IconType } from '@deardigital/shared/constants';
import { MappingFunction } from '@deardigital/shared/interfaces';
import { ReactComponent as ArrowLeft } from '../icons/arrow-left.svg';
import { ReactComponent as ArrowRight } from '../icons/arrow-right.svg';
import { SVGProps } from 'react';

/* eslint-disable-next-line */
export interface IconRendererProps extends SVGProps<SVGSVGElement> {
  icon?: IconType | undefined |null;
}

const componentMapping = new Map<
  IconType,
  MappingFunction<SVGProps<SVGSVGElement>, JSX.Element>
>([
  [ICONS.arrowLeft, (props) => <ArrowLeft {...props} />],
  [ICONS.arrowRight, (props) => <ArrowRight {...props} />],
]);

export function IconRenderer({ icon, ...restProps}: IconRendererProps) {
  if (!icon) {
    return null
  }

  const componentControl = componentMapping.get(icon);
  if (!componentControl) {
    return null
  }

  return componentControl(restProps);
}

export default IconRenderer;
