import { BREAKPOINTS } from '@deardigital/shared/constants';
import { ImageScrSetInterface } from '@deardigital/shared/interfaces';
import { AssetStoryblok } from '@deardigital/shared/schema';
import isEmpty from 'lodash.isempty';
import { ratioWxHCalculation } from './ratio-height-calculator';
import { getNextBreakpoint } from './get-next-breakpoint';

export function imageSourceSetGenerator(media: AssetStoryblok | undefined, ratio?: string, imageSrcSetConfig?: ImageScrSetInterface): string | null {
  if (!media || !imageSrcSetConfig) return null;

  const srcSetArray = Object.entries(imageSrcSetConfig).reduce((acc, [breakpoint, width]) => {
    if(!width || !ratio) return acc;

    const containerWidth = getNextBreakpoint(breakpoint as keyof typeof BREAKPOINTS);
    if(!containerWidth) {
      return acc;
    }

    const itemsPerRow = width;
    const pixelWidth = Math.round(containerWidth[1] / itemsPerRow);
    const WxH = ratioWxHCalculation(breakpoint as keyof typeof BREAKPOINTS, pixelWidth, ratio);

    if(!WxH) return acc;

    const entry = `${media.filename}/m/${WxH} ${pixelWidth}w`;
    acc.push(entry);

    return acc;
  }, [] as string[]); // fix: initialize the accumulator as an empty string array


  if(isEmpty(srcSetArray)) return null;

  return srcSetArray.join(', ');
}
