'use client';

import { ContentYoutubeInterface } from '@deardigital/shared/interfaces';
import { useState } from 'react';
import ImageRenderer from '../image-renderer/image-renderer';
import Section from '../section/section';
import { ReactComponent as Play } from '../icons/play.svg';
import dynamic from 'next/dynamic';
import SectionBadge from '../badge/section-badge/section-badge';
const ReactPlayer = dynamic(() => import('react-player/lazy'), { ssr: false });

/* eslint-disable-next-line */
export interface ContentyoutubeProps extends ContentYoutubeInterface {}

export function ContentYoutube({ container, youtube, thumbnail, section }: ContentyoutubeProps) {
  const [playing, setPlaying] = useState(false);
  const thumbnailCss = playing ? 'hidden' : 'block';

  function togglePlaying() {
    setPlaying(!playing);
  }

  return (
    <Section {...section}>
      <div className={container ? 'mx-auto max-w-screen-xl px-5' : ''}>
        {section.badge && <SectionBadge {...section.badge} spacing={section.spacing} />}
        <div className="relative aspect-video w-100">
          <div className={`absolute inset-0 flex justify-center overflow-hidden ${thumbnailCss}`}>
            <div className="absolute flex inset-0 justify-center">
              <button className="after:absolute after:inset-0" onClick={() => togglePlaying()}>
                <Play className="h-20 text-white" />
              </button>
            </div>
            <ImageRenderer className="w-full object-cover" image={thumbnail} />
          </div>
          <ReactPlayer url={youtube} width={'100%'} height={'100%'} playing={playing} />
        </div>
      </div>
    </Section>
  );
}

export default ContentYoutube;
