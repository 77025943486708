import BaseLink from './base-link';

/* eslint-disable-next-line */
export interface TagLinkProps {
  link: string;
  label: string;
}

export function TagLink(props: TagLinkProps) {
  return (
    <BaseLink
      type="page"
      linkStyle="text-indigo"
      link={props.link}
      label={props.label}
      className="inline-block items-center rounded-xl bg-lightBlue-400 text-indigo-800 px-3 py-1 text-xs"
    />
  );
}

export default TagLink;
