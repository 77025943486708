"use client"

import { ContentImageScrollCarouselInterface } from '@deardigital/shared/interfaces';
import { calculateColumnDivisionFactor } from '@deardigital/shared/utilities';
import { storyblokEditable } from '@storyblok/react';
import { motion, useAnimation } from 'framer-motion';
import { useEffect, useState } from 'react';
import ImageRenderer from '../../image-renderer/image-renderer';
import Section from '../../section/section';
import Link from 'next/link';

/* eslint-disable-next-line */
export interface ContentImageScrollCarouselProps extends ContentImageScrollCarouselInterface {}

/**
 * @documentation https://codesandbox.io/s/framer-motion-scroll-velocity-r1dy4u
 */
export function ContentImageScrollCarousel(props: ContentImageScrollCarouselProps) {
  const [windowWidth, setWindowWidth] = useState(0);
  const animationControls = useAnimation(); // Animation controls for starting/stopping animation
  const width = (windowWidth / calculateColumnDivisionFactor(windowWidth)) * ((props?.images?.length ?? 1) / 2) * -1;

  const marqueeVariants = {
    animate: {
      x: [0, width],
      transition: {
        x: {
          repeat: Infinity,
          repeatType: 'loop',
          duration: (props?.images?.length ?? 1) * 3,
          ease: 'linear',
        },
      },
    },
  };

  const handleResize = () => {
    setWindowWidth(window.innerWidth);
  };

  const handleHoverStart = () => {
    animationControls.stop();
  };

  const handleHoverEnd = () => {
    animationControls.start({ x: [0, -width] }); // Restart animation from the beginning
  };

  useEffect(() => {
    setWindowWidth(window.innerWidth);

    // Attach the event listener
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    animationControls.start({ x: [0, width] }); // Start animation on page load
  }, [animationControls, width]);

  if (!props.images) return null;
  return (
    <Section {...props.section}>
      <div className="relative overflow-x-hidden" {...(props._editable ? { ...storyblokEditable(props as any) } : {})}>
        <motion.div className="flex whitespace-nowrap" variants={marqueeVariants} animate="animate">
          {props.images.map((image, key) => (
            <div key={key} className="w-1/2 md:w-1/3 lg:w-1/4 basis-auto grow-0 shrink-0 my-auto px-4">
              <Link className={!image.link ? 'pointer-events-none' : ''} href={image.link?.link ?? '/'}>
                <ImageRenderer className="rounded-3xl" image={image} />
              </Link>
            </div>
          ))}
        </motion.div>
      </div>
    </Section>
  );
}

export default ContentImageScrollCarousel;
