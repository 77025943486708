'use client';

import { GlobalInterface, NavigationItemInterface } from '@deardigital/shared/interfaces';
import { CustomFlowbiteTheme, Flowbite, Navbar } from 'flowbite-react';
import { useState } from 'react';
import { ReactComponent as Logo } from '../icons/dear-digital-logo.svg';
import { MenuItem } from './menu-item';
import { NavigationCollapse } from './navigation/navigation-collapse';

/* eslint-disable-next-line */
export interface HeaderProps {
  globals: GlobalInterface | null;
  hasHero: boolean;
}

const headerTheme: CustomFlowbiteTheme = {
  navbar: {
    root: {
      base: 'rb',
      inner: {
        base: 'ib mx-auto flex flex-wrap items-center justify-between rounded-b-xl lg:bg-white px-6',
      },
    },
    collapse: {
      base: 'cb basis-full mb-8 lg:mb-0 navbar-collapse mt-10 lg:mt-0 lg:basis-0 grow w-full flex-wrap bg-blue-100 lg:bg-transparent lg:ml-auto lg:inline-block lg:w-auto',
      list: 'cl flex lg:items-center flex-col lg:flex-row lg:ml-auto lg:justify-end lg:mt-0 space-y-10 lg:space-x-8 lg:space-y-0',
    },
    link: {
      base: 'text-3xl lg:text-base block py-2 pl-3 pr-4 rounded',
      active: {
        on: 'text-white bg-blue-700 lg:bg-transparent text-indigo-800 font-medium lg:p-0',
        off: 'text-gray-900 lg:hover:text-blue-700 text-indigo-800 font-medium lg:p-0',
      },
    },
    toggle: {
      base: 'lg:hidden bg-red-500',
    },
  },
};

export function Header({ globals, hasHero }: HeaderProps) {
  const [background, setBackground] = useState('bg-transparent');
  const [logoColor, setLogoColor] = useState('text-white');
  const [transparent, setTransparent] = useState('bg-transparent');
  const [menuItem, setMenuItem] = useState<NavigationItemInterface | null>(null);
  const [showMenuItem, setShowMenuItem] = useState(false);
  const positionClassName = hasHero ? 'fixed' : 'relative';

  // const pathname = usePathname();
  function isActivePage(link: string | null | undefined) {
    return false;
    // return pathname.indexOf(link) > -1;
  }

  const showMenuWithNav = (item: NavigationItemInterface, show: boolean) => {
    if (show) {  
      if (item?.cards && item?.link) {
        setMenuItem(item);
        setShowMenuItem(true);
      }
    } else {
      setShowMenuItem(false);
    }
  };

  function handleClick() {
    setBackground(background === 'bg-transparent' ? 'bg-blue-100 lg:bg-transparent' : 'bg-transparent');
    setLogoColor(logoColor === 'text-white' ? 'text-indigo-800' : 'text-white');
    setTransparent(transparent === 'bg-transparent' ? 'bg-white' : 'bg-transparent');
  }

  return (
    <Flowbite theme={{ theme: headerTheme }}>
      <header className={`${positionClassName} z-40 top-0 w-full lg:bg-transparent`}>
        <Navbar fluid className={`${background} sticky z-40`}>
          <div
            className={`p-4 lg:p-0 w-full flex justify-between lg:w-auto lg:inline-block rounded-2xl ${transparent}`}
          >
            <Navbar.Brand href="/">
              <Logo className={`mr-3 h-8	md:h-10 ${logoColor} lg:text-indigo-800`} />
              <span className="sr-only">dear digital</span>
            </Navbar.Brand>
            <div onClick={() => handleClick()}>
              <Navbar.Toggle aria-expanded="false" className="bg-blue-600  text-white p-2 rounded-full" />
            </div>
          </div>
          <NavigationCollapse globals={globals} showMenuWithNav={showMenuWithNav} isActivePage={isActivePage} />
        </Navbar>
        <div
          onMouseEnter={() => setShowMenuItem(true)}
          onMouseLeave={() => setShowMenuItem(false)}
          className="hidden lg:block"
        >
          {showMenuItem && <MenuItem itemData={menuItem} />}
        </div>
      </header>
    </Flowbite>
  );
}

export default Header;
