"use client";

import { ColorThemeType } from '@deardigital/shared/constants';
import { MediaInterface, WorkCardInterface } from '@deardigital/shared/interfaces';
import { motion } from 'framer-motion';
import Link from 'next/link';
import { useEffect, useRef, useState } from 'react';
import ImageRenderer from '../image-renderer/image-renderer';
import TagLink from '../link/tag-link';

/* eslint-disable-next-line */
export interface WorkCardProps extends WorkCardInterface {
  theme?: ColorThemeType | null;
}
export function WorkCard({ thumbnail, title, services, client, slug, theme }: WorkCardProps) {
  const [isHovered, setIsHovered] = useState(false);
  const [animationMedia, setAnimationMedia] = useState<null | MediaInterface[]>(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const intervalRef = useRef<NodeJS.Timeout | undefined>();

  const handleHover = () => {
    setIsHovered(true);
    setAnimationMedia(thumbnail);
    intervalRef.current = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % (thumbnail?.length || 1));
    }, 750);
  };
  const handleMouseLeave = () => {
    setIsHovered(false);
    clearInterval(intervalRef.current);
  };
  useEffect(() => {
    return () => {
      clearInterval(intervalRef.current);
    };
  }, []);

  return (
    <div onMouseEnter={handleHover} onMouseLeave={handleMouseLeave}>
      <div className="relative">
        <figure className="aspect-square">
          {animationMedia &&
            animationMedia.length > 0 &&
            animationMedia.map((image, index) => (
              <motion.div
                key={index}
                initial={{ opacity: 0 }}
                animate={{ opacity: isHovered ? (index === currentIndex ? 1 : 0) : 0 }}
                transition={{ duration: 1 }}
              >
                <ImageRenderer className="object-cover rounded-lg w-full absolute inset-0 z-1" image={image} />
              </motion.div>
            ))}
          {thumbnail && thumbnail[0] && (
            <ImageRenderer
              className={`object-cover rounded-2xl w-full ${isHovered ? '' : 'opacity-100'}`}
              image={thumbnail[0]}
            />
          )}
        </figure>
        <div className="pt-4">
          <div className={theme?.editor}>
            <h4 className={theme?.color.heading}>
              <Link className="font-sans after:absolute after:inset-0" href={slug}>
                {title && title}
              </Link>
            </h4>
          </div>
        </div>
      </div>

      <div className="flex flex-wrap pt-3 gap-2">
        {services.slice(0, 5).map((service, key) => (
          <TagLink key={key} link={`/${service.slug}`} label={service.title} />
        ))}
      </div>
    </div>
  );
}
export default WorkCard;
