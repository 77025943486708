'use client';

import { LINK_STYLES, LINK_TYPES } from '@deardigital/shared/constants';
import { ServiceCardInterface } from '@deardigital/shared/interfaces';
import LinkRenderer from '../link/link-renderer.component';
import { useParams } from 'next/navigation';

export interface ServicesFilterProps {
  services: ServiceCardInterface[];
}

export function ServicesFilter({ services }: ServicesFilterProps) {
  const params = useParams();

  const getLinkStyle = (id?: string) => {
    const isActive = id === params?.filter;
    if (!isActive) {
      return LINK_STYLES.indigoFilterOutline;
    }

    return LINK_STYLES.indigoFilter;
  }

  const getLink = (id?: string) => {
    const isActive = id === params?.filter;
    if (!isActive) {
      return `/work/services/${id}`;
    }

    return '/work';
  };

  return (
    <div className='flex gap-3 md:gap-6 flex-wrap w-full'>
      {services.map((service, key) => (
        <LinkRenderer 
          key={key}
          link={getLink(service.id)}
          label={service.title}
          type={LINK_TYPES.web}
          linkStyle={getLinkStyle(service.id)}
        />
      ))}
    </div>
  )
}
