import { BREAKPOINTS } from '@deardigital/shared/constants';
import { ImageScrSetInterface } from '@deardigital/shared/interfaces';
import isEmpty from 'lodash.isempty';

export function imageSizesGenerator(imageSrcSetConfig?: ImageScrSetInterface): string | null {
  if (!imageSrcSetConfig) return null;

  const sizes = Object.entries(imageSrcSetConfig || {}).reduce((acc, [breakpoint, width]) => {
    if (!width) return acc;

    const vwWidth = `${Math.round(100 / width)}vw`;

    if (breakpoint === 'xs') {
      acc.push(vwWidth);
      return acc;
    }

    const breakpointWidth = BREAKPOINTS[breakpoint as keyof typeof BREAKPOINTS];
    const entry = `(min-width: ${breakpointWidth}px) ${vwWidth}`;
    acc.push(entry);
    return acc;
  }, [] as string[]);

  if (isEmpty(sizes)) return null;

  return sizes.join(', ');
}
