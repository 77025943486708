export const SPACINGS = {
  sm: 'py-5 md:py-8',
  md: 'py-12 md:py-20',
  lg: 'py-20 md:py-32',
  smnone: 'pt-5 md:pt-12',
  mdnone: 'pt-12 md:pt-20',
  lgnone: 'pt-20 md:pt-32',
  nonesm: 'pb-5 md:pb-12',
  nonemd: 'pb-12 md:pb-20',
  nonelg: 'pb-20 md:pb-32',
  mdsm: 'pt-12 pb-5 md:pt-20 md:pb-8',
};

type ObjectValues<T> = T[keyof T];
export type SpacingType = ObjectValues<typeof SPACINGS>;
