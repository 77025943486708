import { PAGE_TYPES } from '@deardigital/shared/constants';

export function getRevalidateSlug(full_slug: string) {
  if (!full_slug) {
    return `/`;
  }

  const isPageTypePage = full_slug.startsWith(PAGE_TYPES.page);

  if (isPageTypePage) {
    return `/${full_slug.replace(PAGE_TYPES.page, '')}`;
  }

  return `${full_slug}`;
}
