import { BadgeInterface } from '@deardigital/shared/interfaces';
import LightBlueBadge from '../light-blue-badge';

export interface SectionBadgeProps extends BadgeInterface {
  spacing?: string | null;
}

export function SectionBadge({ label, position, spacing }: SectionBadgeProps) {
  return (
    <div className={`flex ${spacing} ${position}`}>
      <LightBlueBadge>{label}</LightBlueBadge>
    </div>
  );
}

export default SectionBadge;
