import { MediaInterface } from '@deardigital/shared/interfaces';
export interface ImageRendererProps {
  image: MediaInterface | null;
  className?: string;
  sizes?: string;
}
export function ImageRenderer({ image, className = '' }: ImageRendererProps) {
  if (!image) {
    return null;
  }
    return (
    <img
      src={image.url}
      className={`${image.ratio || ''} ${className}`}
      alt={image.alt || ''}
      {...(image.srcSet ? { srcSet: image.srcSet } : {})}
      {...(image.sizes ? { sizes: image.sizes } : {})}
      loading='eager'
    />
  );
}
export default ImageRenderer;
