import { NavigationItemCardInterface } from '@deardigital/shared/interfaces';
import ImageRenderer from '../../image-renderer/image-renderer';
import LinkRenderer from '../../link/link-renderer.component';

export interface NavigationItemCardProps extends NavigationItemCardInterface {}

export function NavigationItemCard(card: NavigationItemCardProps) {
  if (!card || !card.cta) {
    return null;
  }

  return (
    <div className="relative lg:w-1/2 rounded-xl bg-white overflow-hidden flex mb-4 lg:mb-0">
      <div className="w-1/3 lg:w-1/2">
        {card.image && <ImageRenderer className="aspect-square w-screen object-cover max-h-72 h-full" image={card.image} />}
      </div>
      <div className="p-3 md:py-12 lg:py-24 w-2/3 lg:w-1/2 lg:px-8 flex items-center">
        <div>
          <LinkRenderer
            {...card.cta}
            className="text-lg after:absolute after:inset-0 font-semibold text-indigo-800"
            label={card.title}
          />
          <p className="text-sm text-blue-800">{card.subTitle}</p>
        </div>
      </div>
    </div>
  );
}
