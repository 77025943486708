/* eslint-disable-next-line */
export interface BadgeProps {
  children: React.ReactNode;
  className?: string;
}

export function BaseBadge(props: BadgeProps) {
  return (
    <span className={`px-5 py-1 rounded-full font-sans inline-block ${props.className}`}>
      {props.children}
    </span>
  );
}

export default BaseBadge;
