'use client';

import { ColorThemeType } from '@deardigital/shared/constants';
import { Field, Form, Formik, FormikHelpers } from 'formik';
import { useState } from 'react';
import { ReactComponent as ArrowRight } from '../icons/arrow-right.svg';
import { emailSubmit } from './email-submit';
import { validationSchema } from './validation';

interface Values {
  email: string;
}
export interface NewsletterSubscribeProps {
  placeholder: string;
  theme?: ColorThemeType;
  id?: string;
}

export function NewsletterSubscribe(props: NewsletterSubscribeProps) {
  const [message, setMessage] = useState('');
  const formConfig = {
    initialValues: {
      email: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: Values, { resetForm }: FormikHelpers<Values>) => {
      function onSuccess() {
        setMessage('Thanks for subscribing');
        resetForm();
      }
      function onError(error: string) {
        setMessage(error);
      }
      emailSubmit(values.email, onSuccess, onError);
    },
  };
  return (
    <div>
      <Formik {...formConfig}>
        {({ errors, touched }) => (
          <Form className="space-y-6">
            {message && <p className="text-sm text-green-400">{message}</p>}
            {errors.email && touched.email ? <div className="text-sm text-red-500 mt-4">{errors.email}</div> : null}

            <label htmlFor="email" className="sr-only">
              Email
            </label>
            <div className={`relative py-3  flex justify-between items-center`}>
              <Field
                id={`email_${props.id}`}
                name="email"
                placeholder={props.placeholder}
                className={`absolute w-full pl-5 lg:pl-6 pr-12 py-4 border ${props.theme?.color.text} ${props.theme?.input.border} rounded-full focus:outline-none bg-transparent focus:ring-0  placeholder:font-sans placeholder:text-sm ${props.theme?.input.placeholderColor}`}
                type="email"
              />

              <button type="submit" aria-label="submit" className="absolute top-0 right-0 pt-2 pr-5 lg:pr-6">
                <ArrowRight className={`h-3 w-3 ${props.theme?.color.text}`} />
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
}

export default NewsletterSubscribe;
