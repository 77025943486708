'use client'
import { MediaInterface } from '@deardigital/shared/interfaces';
import dynamic from 'next/dynamic';

const ReactPlayer = dynamic(() => import('react-player/file').then((ReactPlayer) => ReactPlayer), {
  ssr: false,
})


/* eslint-disable-next-line */
export interface PlayerProps extends MediaInterface {}

export function Player(props: PlayerProps) {
  return (
    <ReactPlayer url={props.url} width={'100%'} height={"100%"} playing={true} loop={true} playsinline={true} muted={true} className="w-full" />
  );
}

export default Player;
