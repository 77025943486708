import { Field, Form, Formik, FormikConfig } from 'formik';
import { Values } from './klaviyo-form';
import { LINK_STYLES, LINK_TYPES, ICONS } from '@deardigital/shared/constants';
import LinkRenderer from '../link/link-renderer.component';

export interface FormFieldsProps {
  formConfig: FormikConfig<Values>;
}

export function FormFields({ formConfig }: FormFieldsProps) {
  return (
    <Formik {...formConfig}>
      {({ errors, touched }) => (
        <Form className="space-y-6">
          <div>
            <div className="mb-6">
              <label className="sr-only" htmlFor="email">
                Email
              </label>
              <Field
                id={`email`}
                name="email"
                placeholder={'Email'}
                className={`w-full pl-5 lg:pl-6 pr-12 py-4 border rounded-full focus:outline-none bg-transparent focus:ring-0  placeholder:font-sans placeholder:text-sm`}
                type="email"
              />
              {errors.email && touched.email ? <span className="text-sm text-red-500">{errors.email}</span> : null}
            </div>

            <div className="mb-6">
              <label className="sr-only" htmlFor="name">
                Name
              </label>
              <Field
                id={`name`}
                name="name"
                placeholder={'Full name'}
                className={`w-full pl-5 lg:pl-6 pr-12 py-4 border rounded-full focus:outline-none bg-transparent focus:ring-0  placeholder:font-sans placeholder:text-sm`}
                type="text"
              />
              {errors.name && touched.name ? <span className="text-sm text-red-500">{errors.name}</span> : null}
            </div>

            <div className="mb-6">
              <label className="sr-only" htmlFor="company">
                Company
              </label>
              <Field
                id={`company`}
                name="company"
                placeholder={'Company name'}
                className={`w-full pl-5 lg:pl-6 pr-12 py-4 border rounded-full focus:outline-none bg-transparent focus:ring-0  placeholder:font-sans placeholder:text-sm`}
                type="text"
              />
              {errors.company && touched.company ? (
                <span className="text-sm text-red-500">{errors.company}</span>
              ) : null}
            </div>
          </div>

          <button type="submit" aria-label="submit" className="pr-5 lg:pr-6">
            <LinkRenderer
              label="Submit"
              linkStyle={LINK_STYLES.blue}
              link="/"
              type={LINK_TYPES.web}
              trailingIcon={ICONS.arrowRight}
            />
          </button>
        </Form>
      )}
    </Formik>
  );
}
