import { NavigationItemInterface } from '@deardigital/shared/interfaces';
import { Accordion } from 'flowbite-react';
import LinkRenderer from '../../link/link-renderer.component';
import { NavigationItemCard } from './navigation-item-card';

export interface NavigationItemAccordionProps {
  item: NavigationItemInterface;
}

export function NavigationItemAccordion({ item }: NavigationItemAccordionProps) {
  return (
    <Accordion collapseAll className="bg-transparent border-0 pl-3 lg:pl-0">
      <Accordion.Panel>
        <Accordion.Title className="text-3xl text-indigo-800 py-2 lg:hover:text-blue-700 bg-transparent border-0 rounded-none p-0 m-0 hover:bg-transparent active:ring-0 focus:ring-0">
          {item.link?.label}
        </Accordion.Title>
        <Accordion.Content className="p-0 m-0 mt-8">
          {item?.cta && (
            <div className="mb-10">
              <h3 className="text-xl hidden lg:block font-medium font-serif text-indigo-800 mb-4">{item.cta.title}</h3>
              <ul>
                {item.cta.links?.map((link, index) => (
                  <li className="mb-6" key={index}>
                    <LinkRenderer key={index} {...link} />
                  </li>
                ))}
              </ul>
            </div>
          )}

          {item?.links?.map((section, index) => (
            <div key={index} className="mb-8">
              <h3 className="font-medium text-xl font-serif text-indigo-800 mb-4">{section.title}</h3>
              <ul>
                {section.links.map((link, index) => (
                  <li className="mb-3" key={index}>
                    <LinkRenderer className="text-indigo-700" key={index} {...link} />
                  </li>
                ))}
              </ul>
            </div>
          ))}
          <div>{item?.cards?.map((card, index) => <NavigationItemCard key={index} {...card} />)}</div>
        </Accordion.Content>
      </Accordion.Panel>
    </Accordion>
  );
}
