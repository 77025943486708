export const COLORS = {
  light: 'text-white',
  blue800: 'text-indigo-800',
  blue500: 'text-indigo-500',
  navy500: 'text-blue-800',
  gray600: 'text-gray-600',
} as const;

type ObjectValues<T> = T[keyof T];
export type ColorType = ObjectValues<typeof COLORS>;
