import { CollapseItemInterface, SectionInterface } from '@deardigital/shared/interfaces';
import { Accordion } from 'flowbite-react';
import LinkRenderer from '../../link/link-renderer.component';

export interface CollapseItemsProps {
  items: CollapseItemInterface[];
  section: SectionInterface;
}

export function Collapse({ items, section }: CollapseItemsProps) {
  return (
    <Accordion collapseAll className="lg:w-1/2 border-0 bg-transparent">
      {items.map((item, index) => (
        <Accordion.Panel key={index}>
          <Accordion.Title
            className={`text-2xl lg:text-3xl mx-0 px-0 py-8 divide-0 font-serif ${section.theme.color.heading} focus:ring-0 border-0 bg-transparent hover:bg-transparent`}
          >
            {item.title}
          </Accordion.Title>
          <Accordion.Content className="font-sans text-base mx-0 px-0">
            <p className="mb-2 text-gray-600">{item.description}</p>
              {item.links?.map((link, index) => (
                <div key={index} className="mt-5">
                  <LinkRenderer {...link} />
                </div>
              ))}
          </Accordion.Content>
        </Accordion.Panel>
      ))}
    </Accordion>
  );
}
