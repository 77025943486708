import { BACKGROUNDS } from './background';
import { COLORS } from './color';
import { EDITORS } from './editor';

export const COLOR_THEMES = {
  light: {
    editor: EDITORS.light,
    background: BACKGROUNDS.light,
    color: {
      heading: COLORS.blue800,
      text: COLORS.blue500,
      timeline: BACKGROUNDS.dark,
      timelineText: COLORS.blue500,
    },
    input: {
      placeholderColor: 'placeholder:text-indigo-800',
      border: 'border-indigo-800',
    },
  },
  dark: {
    editor: EDITORS.dark,
    background: BACKGROUNDS.dark,
    color: {
      heading: COLORS.light,
      text: COLORS.light,
      timeline: BACKGROUNDS.light,
      timelineText: COLORS.navy500,
    },
    input: {
      placeholderColor: 'placeholder:text-white',
      border: 'border-white',
    },
  },
} as const;

type ObjectValues<T> = T[keyof T];
export type ColorThemeType = ObjectValues<typeof COLOR_THEMES>;
