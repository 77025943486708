'use client';

import { SectionInterface, WhitepaperCardInterface } from '@deardigital/shared/interfaces';
import { FormikHelpers } from 'formik';
import { useState } from 'react';
import ImageRenderer from '../image-renderer/image-renderer';
import { FormFields } from './form-fields';
import { formSubmit } from './form-submit';
import { validationSchema } from './validation';

export interface Values {
  name: string;
  email: string;
  company: string;
}

export interface KlaviyoFormProps {
  section: SectionInterface;
  klaviyoFormId: string;
  thumbnail: WhitepaperCardInterface['thumbnail'];
  thankYouMessage: string;
}

export function KlaviyoForm({ klaviyoFormId, thumbnail, thankYouMessage, section }: KlaviyoFormProps) {
  const [showMessage, setShowMessage] = useState<boolean>(false);
  const formConfig = {
    initialValues: {
      name: '',
      email: '',
      company: '',
    },
    validationSchema: validationSchema,
    onSubmit: (values: Values, { resetForm }: FormikHelpers<Values>) => {
      function onSuccess() {
        setShowMessage(true);
        resetForm();
      }
      formSubmit(values, klaviyoFormId, onSuccess);
    },
  };

  if (!klaviyoFormId || !thumbnail) {
    return null;
  }

  return (
    <div className="grid gap-6 lg:grid-cols-12">
      <div className="order-first lg:col-span-6 py-12 md:py-20">
        {showMessage && (
          <div className={section.theme.editor} dangerouslySetInnerHTML={{ __html: thankYouMessage }} />
        )}
        {!showMessage && <FormFields formConfig={formConfig} />}
      </div>

      <div className="lg:col-end-13 lg:col-span-4">
        {thumbnail && <ImageRenderer className="h-full w-full rounded-3xl object-cover" image={thumbnail} />}
      </div>
    </div>
  );
}

export default KlaviyoForm;
