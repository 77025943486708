import { Navbar } from 'flowbite-react';
import { NavigationItemAccordion } from './navigation-item-accordion';
import Link from 'next/link';
import LinkRenderer from '../../link/link-renderer.component';
import { GlobalInterface, NavigationItemInterface } from '@deardigital/shared/interfaces';

export interface NavigationCollapseProps {
  globals: GlobalInterface | null;
  showMenuWithNav: (item: NavigationItemInterface, show: boolean) => void;
  isActivePage: (link: string | null | undefined) => boolean;
}

export function NavigationCollapse({ globals, showMenuWithNav, isActivePage }: NavigationCollapseProps) {
  return (
    <Navbar.Collapse>
      {globals?.header?.navigation?.map((item, key) => (
        <div key={key}>
          <div className="lg:hidden">
            {item.links && item.links.length > 0 ? (
              <div className="lg:hidden">
                <NavigationItemAccordion item={item} />
              </div>
            ) : (
              <Navbar.Link key={key} href={item.link?.link} as={Link} {...(isActivePage(item.link?.link) ? { active: true } : {})}>
                <div className="lg:hidden">{item.link?.label}</div>
              </Navbar.Link>
            )}
          </div>

          <div
            onMouseEnter={() => showMenuWithNav(item, true)}
            onMouseLeave={() => showMenuWithNav(item, false)}
            className="hidden lg:block pb-8 mt-8"
          >
            <Navbar.Link key={key} href={item.link?.link} as={Link} {...(isActivePage(item.link?.link) ? { active: true } : {})}>
              <p>{item.link?.label}</p>
            </Navbar.Link>
          </div>
        </div>
      ))}
      <li>{globals?.header?.cta && <LinkRenderer {...globals.header.cta} className="mr-3 md:mr-0" />}</li>
    </Navbar.Collapse>
  );
}
