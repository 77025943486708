import { SectionInterface } from '@deardigital/shared/interfaces';
import { ReactNode } from 'react';

/* eslint-disable-next-line */
export interface SectionProps extends SectionInterface {
  children: ReactNode;
  className?: string;
}

export function Section({ theme, children, className = '', slug, spacing, badge }: SectionProps) {
  // If there is a badge, we only add spacing to the bottom because the badge has its own spacing
  const bottomPadding = spacing?.replace(/py-/g, 'pb-');

  return (
    <section
      className={`section ${theme?.background} ${badge ? bottomPadding : spacing} ${className}`}
      {...(slug ? { id: slug } : {})}
    >
      {children}
    </section>
  );
}

export default Section;
