'use client';

/* eslint-disable-next-line */
import { ContentCalendlyInterface } from '@deardigital/shared/interfaces';
import { storyblokEditable } from '@storyblok/react';
import { InlineWidget } from 'react-calendly';
import Section from '../../section/section';
import SectionBadge from "../../badge/section-badge/section-badge";

export interface ContentCalendlyProps extends ContentCalendlyInterface {}

export function ContentCalendly(props: ContentCalendlyProps) {
  if (!props.calendlyLink?.link) return null;

  return (
    <Section {...props.section}>
      <div className="container mx-auto px-5" {...(props._editable ? { ...storyblokEditable(props as any) } : {})}>
      {props.section.badge && <SectionBadge {...props.section.badge} spacing={props.section.spacing} />}
        <div className='grid grid-flow-row lg:grid-cols-12'>
          <div className={`lg:col-start-2 lg:col-end-12 lg:col-span-10`}>
            <InlineWidget url={props.calendlyLink.link} />
          </div>
        </div>
      </div>
    </Section>
  );
}

export default ContentCalendly;
