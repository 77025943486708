import { NavigationItemInterface } from '@deardigital/shared/interfaces';
import LinkRenderer from '../link/link-renderer.component';
import { NavigationItemCard } from './navigation/navigation-item-card';

export interface MenuItemProps {
  itemData: NavigationItemInterface | null;
}

export function MenuItem({ itemData }: MenuItemProps) {
  if (!itemData) {
    return null;
  }

  return (
    <div className="absolute z-10 w-full bg-blue-50 px-5 py-8 -translate-y-8">
      <div className="container mx-auto p-8">
        <div className="flex justify-between mb-8">
          {itemData?.cta && (
            <div>
              <h3 className="text-xl font-medium font-serif text-indigo-800 mb-4">{itemData.cta.title}</h3>
              <ul>
                {itemData.cta.links?.map((link, index) => (
                  <li className="mb-6" key={index}>
                    <LinkRenderer key={index} {...link} />
                  </li>
                ))}
              </ul>
            </div>
          )}

          {itemData?.links?.map((section, index) => (
            <div key={index}>
              <h3 className="font-medium text-xl font-serif text-indigo-800 mb-4">{section.title}</h3>
              <ul>
                {section.links.map((link, index) => (
                  <li className="mb-3" key={index}>
                    <LinkRenderer className="text-indigo-700" key={index} {...link} />
                  </li>
                ))}
              </ul>
            </div>
          ))}
        </div>
        <div className="flex gap-8">
          {itemData?.cards?.map((card, index) => <NavigationItemCard key={index} {...card} />)}
        </div>
      </div>
    </div>
  );
}
